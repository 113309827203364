import React from "react";
import styled from "@emotion/styled";

import { useTranslationContext } from "i18n/i18n";
import useScrollIntoView from "hooks/useScrollIntoView";

const Wrapper = styled.div`
  padding: 1rem 1rem 10rem;
  background-color: ${(props) => props.theme.colors.purple.main};
  color: ${(props) => props.theme.colors.yellow.main};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  div {
    margin: 1rem;
    width: 30%;

    @media (max-width: 850px) {
      width: unset;
    }
  }

  p {
    margin: 0;
  }

  a {
    color: ${(props) => props.theme.colors.petrol.main};

    &:hover {
      color: ${(props) => props.theme.colors.petrol.darker};
    }
  }

  @media (max-width: 850px) {
    justify-content: unset;
  }
`;

const ContactBlockComponent = () => {
  const { t } = useTranslationContext();
  const wrapperRef = useScrollIntoView("contact");

  return (
    <Wrapper ref={wrapperRef}>
      <div>
        <h2>
          {"{"} {t("contact.general")} {"}"}
        </h2>
        <p>E-mail: info@hackintheclass.nl</p>
        <p>Tel. +31 030-369 0219</p>
        <p>IBAN: NL64 RABO 0328 9954 52</p>
        <p>KVK: 71102108</p>
      </div>

      <div>
        <h2>
          {"{"} {t("contact.postal")} {"}"}
        </h2>
        <p>Stichting Hack In The Class</p>
        <p>Zangvogelweg 120</p>
        <p>3815 DP Amersfoort</p>
      </div>

      <div>
        <h2>
          {"{"} {t("contact.irc")} {"}"}
        </h2>
        <p>
          {t("contact.irc_text")} <a href="/irc">{t("contact.irc_link")}</a>
        </p>
        <p>Server: irc.smurfnet.ch</p>
        <p>Channel: #hitc</p>
        <p>SSL-port: 6697</p>
        <p>IRC client Windows: Hexchat / Kiwi IRC</p>
        <p>IRC client Linux/macOS client: irssi</p>
      </div>
    </Wrapper>
  );
};

export default ContactBlockComponent;
