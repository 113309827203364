import { ThemeProvider } from "@emotion/react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { routes } from "./routes/routes";
import NotFoundPage from "./routes/NotFound/NotFoundPage";

const theme = {
  colors: {
    purple: {
      lighter: "#604A8C",
      main: "#3a2860",
      darker: "#1a122b",
    },
    yellow: {
      lighter: "#f4d080",
      main: "#f8c960",
      darker: "#e4ac34",
    },
    petrol: {
      lighter: "#59AEB2",
      main: "#439ca1",
      darker: "#339095",
    },
    red: {
      lighter: "#E36C6A",
      main: "#d75451",
      darker: "#cc4542",
    },
    grey: {
      lighter: "#f4f4f5",
      main: "#c2c7ce",
      darker: "#353535",
    },
  },
  breakpoints: {
    small: "900px",
    large: "1366px",
  },
};

const App = () => (
  <ThemeProvider theme={theme}>
    <Router basename={process.env.PUBLIC_URL}>
      <Switch>
        {routes.map(({ path, exact, component }) => (
          <Route key={path} path={path} exact={exact} component={component} />
        ))}
        <Route component={NotFoundPage} />
      </Switch>
    </Router>
  </ThemeProvider>
);

export default App;
