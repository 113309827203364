import React from "react";
import styled from "@emotion/styled";
import { Swiper, SwiperSlide } from "swiper/react";

import { useTranslationContext } from "i18n/i18n";
import useScrollIntoView from "hooks/useScrollIntoView";

import example from "assets/images/hitc-in-action/example.png";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${(props) => props.theme.colors.purple.main};
`;

const Title = styled.h1`
  text-align: center;
`;

const Wrapper = styled.div`
  width: 100%;
`;

const Gallery = styled(Swiper)`
  height: 40rem;

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: ${(props) => props.theme.colors.purple.main};
  }

  .swiper-pagination-bullet-active {
    background-color: ${(props) => props.theme.colors.purple.main};
  }

  img {
    width: 100%;
  }
`;

const InActionBlockComponent = () => {
  const { t } = useTranslationContext();
  const wrapperRef = useScrollIntoView("action");
  const images = [example, example, example, example];

  return (
    <Content ref={wrapperRef}>
      <Title>
        {"{"} {t("in_action.title")} {"}"}
      </Title>
      <Wrapper>
        <Gallery
          spaceBetween={30}
          loop={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
        >
          {images?.map((img, i) => (
            <SwiperSlide key={`gallery-${i}`}>
              <img src={img} alt="HITC in action" />
            </SwiperSlide>
          ))}
        </Gallery>
      </Wrapper>
    </Content>
  );
};

export default InActionBlockComponent;
