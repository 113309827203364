import React from "react";
import ReactDOM from "react-dom";
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper/core";

import "./index.css";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";

import App from "./App";
import "./i18n/i18n";
import reportWebVitals from "./reportWebVitals";

SwiperCore.use([Autoplay, Pagination, Navigation]);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
