import React, { useState } from "react";
import styled from "@emotion/styled";

import useMediaQuery from "hooks/useMediaQuery";
import { XXS_SCREEN, S_SCREEN, LG_SCREEN, MD_SCREEN } from "./screenSizes";
import Navigation from "./NavigationComponent";
import HeaderLogo from "./HeaderLogoComponent";
import Menu from "./MenuComponent";

const Header = styled.header`
  position: relative;
  background-color: ${(props) => props.theme.colors.purple.main};
  height: ${(props) => (props.hasContent ? "30rem" : "4rem")};
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr 2fr;

  @media (max-width: ${LG_SCREEN}) {
    height: ${(props) => (props.hasContent ? "27rem" : "4rem")};
  }

  @media (max-width: ${S_SCREEN}) {
    height: ${(props) => (props.hasContent ? "24rem" : "4rem")};
  }

  @media (max-width: ${XXS_SCREEN}) {
    display: flex;
    height: 4rem;
  }
`;

const MobileMenu = styled(Navigation)`
  position: absolute;
  right: 0;
`;

const HeaderComponent = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const isMobileScreen = useMediaQuery(`(max-width: ${XXS_SCREEN})`);
  const isSmallScreen = useMediaQuery(`(max-width: ${MD_SCREEN})`);

  return (
    <Header hasContent={children !== undefined ? 1 : 0}>
      {!isMobileScreen && (
        <>
          <HeaderLogo />
          <div>
            <Navigation onOpenMenu={() => setMenuOpen(true)} />
          </div>
          {children}
        </>
      )}
      {isMobileScreen && (
        <>
          <HeaderLogo />
          <MobileMenu onOpenMenu={() => setMenuOpen(true)} />
        </>
      )}
      {isSmallScreen && (
        <Menu show={menuOpen} onClose={() => setMenuOpen(false)} />
      )}
    </Header>
  );
};

export default HeaderComponent;
