import React from "react";
import styled from "@emotion/styled";

import { useTranslationContext } from "i18n/i18n";
import crew1 from "assets/images/crew/crew-1.png";
import crew2 from "assets/images/crew/crew-2.png";
import crew3 from "assets/images/crew/crew-3.png";
import crew4 from "assets/images/crew/crew-4.png";

const Block = styled.div`
  padding: 4rem 5rem;
  background-color: ${(props) => props.theme.colors.yellow.main};
  color: ${(props) => props.theme.colors.purple.main};
  display: grid;
  grid-template-columns: 2fr 1fr;

  @media (max-width: ${(props) => props.theme.breakpoints.large}) {
    grid-template-columns: 1fr;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.small}) {
    padding: 1rem;
  }
`;

const CrewMembers = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-column: 1;
  grid-row: 1;

  @media (max-width: ${(props) => props.theme.breakpoints.large}) {
    grid-row: 2;
  }
`;

const Member = styled.div`
  width: 15rem;
  margin: 0 2rem;
  grid-column: 2;
  grid-row: 1;

  img {
    width: 100%;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.small}) {
    width: 25%;
    min-width: 10rem;
  }
`;

const Info = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.large}) {
    margin-bottom: 1rem;
  }
`;

const CrewBlockComponent = () => {
  const { t } = useTranslationContext();

  return (
    <Block>
      <Info>
        <h1>
          {"{"} {t("crew.title")} {"}"}
        </h1>
        <p>{t("crew.text")}</p>
      </Info>
      <CrewMembers>
        <Member>
          <img src={crew1} alt="Crew member" />
        </Member>
        <Member>
          <img src={crew2} alt="Crew member" />
        </Member>
        <Member>
          <img src={crew3} alt="Crew member" />
        </Member>
        <Member>
          <img src={crew4} alt="Crew member" />
        </Member>
      </CrewMembers>
    </Block>
  );
};

export default CrewBlockComponent;
