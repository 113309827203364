import React from "react";
import styled from "@emotion/styled";

const Overlay = styled.div`
  position: absolute;
  background: #31313182;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  display: ${(props) => (props.show ? "flex" : "none")};
  justify-content: center;
  align-items: center;
`;

const Popup = styled.div`
  position: relative;
  border: 0.5rem solid ${(props) => props.theme.colors.yellow.main};
  background: #f4f4f5;
  width: 25vw;
  height: 25vh;
  min-width: 15rem;
  min-height: 20rem;
  border-radius: 5px;
  z-index: 2;
`;

const CloseButton = styled.span`
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  color: ${(props) => props.theme.colors.petrol.main};
  font-weight: bold;

  &:hover {
    color: ${(props) => props.theme.colors.petrol.darker};
    cursor: pointer;
  }
`;

const Content = styled.div`
  margin: 3rem 1rem 1rem;
`;

const PopupComponent = ({ children, show, onClose }) => {
  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <Overlay show={show} onClick={onClose}>
      <Popup onClick={stopPropagation}>
        <CloseButton onClick={onClose}>X</CloseButton>

        <Content>{children}</Content>
      </Popup>
    </Overlay>
  );
};

export default PopupComponent;
