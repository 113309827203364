import React from "react";
import styled from "@emotion/styled";

import { useTranslationContext } from "i18n/i18n";
import useScrollIntoView from "hooks/useScrollIntoView";
import CardButton from "components/CardButtonComponent";

const Content = styled.div`
  padding: 4rem;
`;

const Title = styled.h1`
  text-align: center;
  color: ${(props) => props.theme.colors.purple.main};
`;

const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const InvolvedBlockComponent = () => {
  const { t } = useTranslationContext();
  const wrapperRef = useScrollIntoView("involved");

  return (
    <Content ref={wrapperRef}>
      <Title>
        {"{"} {t("involved.title")} {"}"}
      </Title>
      <Cards>
        <CardButton>
          <h2>{t("involved.card_1")}</h2>
        </CardButton>
        <CardButton>
          <h2>{t("involved.card_2")}</h2>
        </CardButton>
        <CardButton>
          <h2>{t("involved.card_3")}</h2>
        </CardButton>
        <CardButton>
          <h2>{t("involved.card_4")}</h2>
        </CardButton>
        <CardButton>
          <h2>{t("involved.card_5")}</h2>
        </CardButton>
        <CardButton>
          <h2>{t("involved.card_6")}</h2>
        </CardButton>
      </Cards>
    </Content>
  );
};

export default InvolvedBlockComponent;
