import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";

import i18 from "i18n/i18n";
import useComponentVisible from "hooks/useComponentVisible";
import nlFlag from "assets/images/flags/nl.png";
import enFlag from "assets/images/flags/en.png";

const LanguagePicker = styled.div`
  position: relative;

  &:hover {
    cursor: pointer;
  }

  img {
    margin: 0;
    width: 1.5rem;
    height: 1rem;
  }
`;

const Picker = styled.ul`
  padding: 0;
  position: absolute;
  list-style: none;
  width: 10rem;
  margin: 0;
  top: 1.1rem;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.grey.lighter};
  border: 1px solid ${(props) => props.theme.colors.grey.main};
  box-shadow: -7px 5px 0px 1px ${(props) => props.theme.colors.petrol.main};

  li {
    margin: 0;
    padding: 0.5rem;
    display: flex;
    justify-content: center;

    &:hover {
      cursor: pointer;
      background-color: ${(props) => props.theme.colors.grey.main};
    }
  }
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  span {
    margin-left: 1rem;
  }
`;

const Flag = ({ language, onlyImage, onClick }) => {
  const data = {
    image: null,
    language: "",
  };

  switch (language) {
    case "nl":
      data.image = nlFlag;
      data.language = "Nederlands";
      break;
    case "en":
    default:
      data.image = enFlag;
      data.language = "English";
      break;
  }

  if (onlyImage)
    return <img onClick={onClick} src={data.image} alt={data.language} />;

  return (
    <MenuItem onClick={onClick}>
      <img src={data.image} alt={data.language} />
      <span>{data.language}</span>
    </MenuItem>
  );
};

const LanguagePickerComponent = () => {
  const { language } = i18;
  const [openPicker, setOpenPicker] = useState(false);
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(true);
  const languages = Object.keys(i18.services.resourceStore.data);

  const setLanguage = (language) => {
    i18.changeLanguage(language || "en");
    setOpenPicker(false);
  };

  useEffect(() => {
    if (openPicker) setIsComponentVisible(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openPicker]);

  useEffect(() => {
    if (openPicker && !isComponentVisible) setOpenPicker(isComponentVisible);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isComponentVisible]);

  return (
    <LanguagePicker>
      <Flag
        language={language}
        onClick={() => setOpenPicker(!openPicker)}
        onlyImage={true}
      />
      {openPicker && (
        <Picker ref={ref}>
          {languages
            .filter((l) => l !== language)
            .map((l) => (
              <li key={l}>
                <Flag language={l} onClick={() => setLanguage(l)} />
              </li>
            ))}
        </Picker>
      )}
    </LanguagePicker>
  );
};

export default LanguagePickerComponent;
