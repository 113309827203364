import React from "react";
import styled from "@emotion/styled";

import { S_SCREEN } from "components/header/screenSizes";

const Button = styled.button`
  border: none;
  padding: 0.5rem 2rem;
  border-radius: 1rem;
  background-color: ${(props) => props.theme.colors.grey.lighter};
  color: ${(props) => props.theme.colors.grey.darker};
  -webkit-box-shadow: -3px 3px 0px 1px ${(props) => props.color};
  -moz-box-shadow: -3px 3px 0px 1px ${(props) => props.color};
  box-shadow: -3px 3px 0px 1px ${(props) => props.color};

  font-weight: bold;

  &:hover {
    cursor: pointer;
    -webkit-box-shadow: -8px 6px 0px 1px ${(props) => props.color};
    -moz-box-shadow: -8px 6px 0px 1px ${(props) => props.color};
    box-shadow: -8px 6px 0px 1px ${(props) => props.color};
    transition: box-shadow 0.15s linear;
  }

  @media (max-width: ${S_SCREEN}) {
    font-size: small;
    padding: 0.5rem 1rem;
  }
`;

const ButtonComponent = ({ children, color, ...props }) => {
  return (
    <Button {...props} color={color}>
      {children}
    </Button>
  );
};

export default ButtonComponent;
