import React from "react";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";

import { useTranslationContext } from "i18n/i18n";
import Button from "components/ButtonComponent";

const Wrapper = styled.div`
  padding: 4rem 5rem;
  background-color: ${(props) => props.theme.colors.yellow.main};
  color: ${(props) => props.theme.colors.purple.main};

  @media (max-width: 1000px) {
    padding: 3rem;
  }
`;

const Title = styled.h1`
  text-align: center;
`;

const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Btn = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  margin: 1rem;
  flex-wrap: wrap;

  button {
    width: 10rem;
    white-space: nowrap;
  }
`;

const DonationBlockComponent = () => {
  const { t } = useTranslationContext();
  const themeContext = useTheme();

  return (
    <Wrapper>
      <Title>
        {"{"} {t("donation.title")} {"}"}
      </Title>
      <Buttons>
        <Btn>
          <Button color={themeContext.colors.purple.main}>€ 5</Button>
        </Btn>
        <Btn>
          <Button color={themeContext.colors.purple.main}>€ 50</Button>
        </Btn>
        <Btn>
          <Button color={themeContext.colors.purple.main}>€ 250</Button>
        </Btn>
        <Btn>
          <Button color={themeContext.colors.purple.main}>€ custom</Button>
        </Btn>
      </Buttons>
    </Wrapper>
  );
};

export default DonationBlockComponent;
