import React from "react";
import styled from "@emotion/styled";

import { useTranslationContext } from "i18n/i18n";

import example from "assets/images/programs/example.png";

const Block = styled.div`
  margin: 2rem 0;
  padding: 2rem;
`;

const Programs = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const Program = styled.div`
  border: 1px solid ${(props) => props.theme.colors.purple.main};
  color: ${(props) => props.theme.colors.red.main};
  display: grid;
  grid-template-rows: 1fr;
  text-align: center;
  width: 20rem;
  margin: 0 5rem 2rem;

  img {
    width: 100%;
    min-width: 10rem;
  }

  a {
    border-top: 1px solid ${(props) => props.theme.colors.purple.main};
    text-decoration: none;
    color: ${(props) => props.theme.colors.petrol.main};
  }

  &:hover {
    box-shadow: -9px 11px ${(props) => props.theme.colors.red.main};
    transition: box-shadow 0.15s linear;
    cursor: pointer;
    text-decoration: underline;
  }
`;

const Info = styled.div`
  display: flex;
  justify-content: flex-end;
  color: ${(props) => props.theme.colors.purple.main};
`;

const ProgramBlockComponent = () => {
  const { t } = useTranslationContext();

  return (
    <Block>
      <Programs>
        <Program>
          <img src={example} alt="Padawan" />
          <a href="/program/padawan">Padawan</a>
        </Program>
        <Program>
          <img src={example} alt="X-Men" />
          <a href="/program/x-men">X-Men</a>
        </Program>
        <Program>
          <img src={example} alt="Dragon Warrior" />
          <a href="/program/dragon-warrior">Dragon Warrior</a>
        </Program>
      </Programs>
      <Info>
        <div>
          <h1>
            {"{"} {t("program.title")} {"}"}
          </h1>
          <p>{t("program.text")}</p>
        </div>
      </Info>
    </Block>
  );
};

export default ProgramBlockComponent;
