import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { MdClose } from "react-icons/md";
import { Link } from "react-router-dom";

import { useTranslationContext } from "i18n/i18n";
import useComponentVisible from "hooks/useComponentVisible";
import LanguagePicker from "./LanguagePickerComponent";

const Drawer = styled.div`
  transform: ${(props) => (props.show ? "none" : "translate(25rem, 0)")};
  transition: transform 450ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  z-index: 99;
  width: 20em;
  min-height: 100vh;
  position: absolute;
  background-color: ${(props) => props.theme.colors.grey.lighter};
  right: 0;
  border-left: 1px solid ${(props) => props.theme.colors.grey.main};
  border-bottom: 1px solid ${(props) => props.theme.colors.grey.main};

  @media (max-width: 400px) {
    width: 100%;
    border-left: none;
  }
`;

const CloseButton = styled.button`
  border: none;
  background: none;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  color: ${(props) => props.theme.colors.grey.darker};
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
    opacity: 0.5;
    background-color: ${(props) => props.theme.colors.grey.main}54;
  }

  svg {
    height: 2rem;
    width: 2rem;
  }
`;

const Menu = styled.nav`
  margin: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 400px) {
    margin: 3rem 0 0 0;
  }
`;

const MenuItem = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.colors.grey.darker};
  font-size: x-large;
  margin-bottom: 0.5rem;
  width: 100%;
  text-align: center;

  &:hover {
    background-color: ${(props) => props.theme.colors.grey.main}24;
  }
`;

const MenuComponent = ({ show, onClose }) => {
  const { t } = useTranslationContext();
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(true);

  useEffect(() => {
    if (!isComponentVisible) onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isComponentVisible]);

  useEffect(() => {
    setIsComponentVisible(show);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <Drawer show={show ? 1 : 0} ref={ref}>
      <CloseButton onClick={onClose}>
        <MdClose />
      </CloseButton>
      <Menu>
        <LanguagePicker />
        <MenuItem to="/" className="active">
          {t("navigation.home")}
        </MenuItem>
        <MenuItem to="/teaching-materials">
          {t("navigation.teaching_materials")}
        </MenuItem>
        <MenuItem to="/#mission">{t("navigation.mission")}</MenuItem>
        <MenuItem to="/#contact">{t("navigation.contact")}</MenuItem>
      </Menu>
    </Drawer>
  );
};

export default MenuComponent;
