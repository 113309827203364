import React from "react";
import styled from "@emotion/styled";

import { useTranslationContext } from "i18n/i18n";
import useScrollIntoView from "hooks/useScrollIntoView";
import example from "assets/images/example.png";

const Wrapper = styled.div`
  display: flex;
  padding: 2rem 2rem 5rem;
  color: ${(props) => props.theme.colors.purple.main};

  @media (max-width: 1300px) {
    flex-wrap: wrap;
  }
`;

const Image = styled.img`
  margin-right: 4rem;

  @media (max-width: 700px) {
    margin-right: unset;
    width: 100%;
    max-width: 300px;
  }
`;

const MissionBlockComponent = () => {
  const { t } = useTranslationContext();
  const wrapperRef = useScrollIntoView("mission");

  return (
    <Wrapper ref={wrapperRef}>
      <Image src={example} alt="example" />
      <div>
        <h1>
          {"{"} {t("mission.title")} {"}"}
        </h1>

        <p>{t("mission.text")}</p>
      </div>
    </Wrapper>
  );
};

export default MissionBlockComponent;
