import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const useScrollIntoView = (hash) => {
  const location = useLocation();
  const wrapperRef = useRef(null);

  useEffect(() => {
    if (location.hash === `#${hash}` && wrapperRef)
      wrapperRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
  }, [hash, location]);

  return wrapperRef;
};

export default useScrollIntoView;
