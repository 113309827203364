import React from "react";

import Header from "components/header/HeaderComponent";
import HeaderContent from "./components/HeaderContentComponent";
import CrewBlock from "./components/CrewBlockComponent";
import MissionBlock from "./components/MissionBlockComponent";
import ContactBlock from "./components/ContactBlockComponent";
import ProgramBlock from "./components/ProgramBlockComponent";
import SchoolBlock from "./components/SchoolBlockComponent";
import InvolvedBlock from "./components/InvolvedBlockComponent";
import InActionBlock from "./components/InActionBlockComponent";
import DonationBlock from "./components/DonationBlockComponent";

const HomePage = () => {
  return (
    <>
      <Header>
        <HeaderContent />
      </Header>
      <MissionBlock />
      <CrewBlock />
      <ProgramBlock />
      <SchoolBlock />
      <InvolvedBlock />
      <DonationBlock />
      <InActionBlock />
      <ContactBlock />
    </>
  );
};

export default HomePage;
