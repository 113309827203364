import { useState, useEffect } from "react";

const useMediaQuery = (mediaQuery) => {
  const [isVerified, setIsVerified] = useState();

  useEffect(() => {
    try {
      setIsVerified(!!window?.matchMedia(mediaQuery).matches);
    } catch {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    try {
      const mediaQueryList = window.matchMedia(mediaQuery);
      const documentChangeHandler = () =>
        setIsVerified(!!mediaQueryList.matches);

      mediaQueryList.addEventListener("change", documentChangeHandler);

      documentChangeHandler();
      return () => {
        mediaQueryList.removeEventListener("change", documentChangeHandler);
      };
    } catch {}
  }, [mediaQuery]);

  return isVerified;
};

export default useMediaQuery;
