import React from "react";
import styled from "@emotion/styled";

import { useTranslationContext } from "i18n/i18n";
import useMediaQuery from "hooks/useMediaQuery";
import robotImage from "assets/images/error-robot.png";
import Header from "components/header/HeaderComponent";

const HeaderContent = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-column: span 2;
  z-index: 1;
`;

const Title = styled.h1`
  background-color: ${(props) => props.theme.colors.yellow.main};
  padding: 1rem 2rem;
  margin-bottom: 0.5rem;

  @media (max-width: 600px) {
    font-size: 10vw;
    padding: 1rem;
  }
`;

const Robot = styled.img`
  position: absolute;
  bottom: 0;
  margin: 0;
  right: 2rem;
  height: 20rem;
`;

const NotFoundPage = () => {
  const { t } = useTranslationContext();
  const isSmallScreen = useMediaQuery("(max-width: 600px)");

  if (isSmallScreen) {
    return (
      <HeaderContent>
        <Title>404</Title>
        <Title>{t("error.page_not_found")}</Title>
      </HeaderContent>
    );
  }

  return (
    <Header>
      <HeaderContent>
        <Title>404</Title>
        <Title>{t("error.page_not_found")}</Title>
      </HeaderContent>
      <Robot src={robotImage} alt="Cringy robot" />
    </Header>
  );
};

export default NotFoundPage;
