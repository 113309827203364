import React from "react";
import styled from "@emotion/styled";

const Card = styled.div`
  width: 16rem;
  height: 16rem;
  margin: 2rem;
  background-color: ${(props) =>
    props.bgColor || props.theme.colors.purple.main};
  color: ${(props) => props.color || props.theme.colors.yellow.main};
  padding: 2rem;
  display: flex;
  align-items: center;
  text-align: center;

  h2 {
    flex: 1;
  }

  &:hover {
    box-shadow: -9px 11px
      ${(props) => props.shadow || props.theme.colors.petrol.main};
    transition: box-shadow 0.15s linear;
    cursor: pointer;
  }

  @media (max-width: 550px) {
    margin: 2rem 0;
  }
`;

const CardButtonComponent = ({
  children,
  bgColor,
  color,
  shadow,
  ...props
}) => {
  return (
    <Card {...props} bgColor={bgColor} color={color} shadow={shadow}>
      {children}
    </Card>
  );
};

export default CardButtonComponent;
