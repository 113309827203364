import React from "react";
import styled from "@emotion/styled";

import useMediaQuery from "hooks/useMediaQuery";
import logo from "assets/images/logo-full.svg";
import logoSmall from "assets/images/logo.svg";
import { XXS_SCREEN } from "./screenSizes";

const MINI_SCREEN = "300px";

const Logo = styled.img`
  height: 7em;
  padding: 1rem 0 0 1rem;

  @media (max-width: ${XXS_SCREEN}) {
    height: 5em;
    padding: 0.5rem 0 0 0.5rem;
  }

  @media (max-width: ${MINI_SCREEN}) {
    padding: 0.5rem 0 0 0.5rem;
    height: 3.5em;
  }
`;

const HeaderLogoComponent = () => {
  const isSmallScreen = useMediaQuery(`(max-width: ${MINI_SCREEN})`);

  return (
    <Logo src={isSmallScreen ? logoSmall : logo} alt="Logo Hack in the Class" />
  );
};

export default HeaderLogoComponent;
