import React from "react";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";

import { useTranslationContext } from "i18n/i18n";
import useMediaQuery from "hooks/useMediaQuery";
import Button from "components/ButtonComponent";
import headerImage from "assets/images/index-header.png";
import headerImageSmall from "assets/images/index-header-small.png";
import headerArrow from "assets/images/header-arrow.svg";
import {
  XS_SCREEN,
  S_SCREEN,
  MD_SCREEN,
  LG_SCREEN,
} from "components/header/screenSizes";

const Content = styled.div`
  grid-column: span 2;
  display: grid;
  grid-template-columns: 2fr 1fr;

  @media (max-width: ${LG_SCREEN}) {
    grid-template-columns: 5fr 1fr;
  }

  @media (max-width: ${MD_SCREEN}) {
    display: unset;
    margin: 0 4rem;
  }

  @media (max-width: ${XS_SCREEN}) {
    margin: 0 1rem;
  }
`;

const HeaderImage = styled.div`
  display: flex;
  justify-content: flex-end;

  img {
    height: 21rem;
    margin: 0 4rem 0 0;
  }
`;

const HeaderWrapper = styled.div`
  color: ${(props) => props.theme.colors.grey.lighter};
`;

const HeaderText = styled.div`
  margin: 0 4rem;
  line-height: 3.5rem;

  & > h3 {
    display: inline;
    margin: 0 0.5rem;
  }

  @media (max-width: ${MD_SCREEN}) {
    margin: 0;
  }

  @media (max-width: ${S_SCREEN}) {
    line-height: 2.5rem;

    h3 {
      font-size: medium;
    }
  }
`;

const YellowBar = styled.h3`
  color: ${(props) => props.theme.colors.purple.main};
  background-color: ${(props) => props.theme.colors.yellow.main};
  padding: 0.25rem 1rem;
`;

const ActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

const ActionText = styled.div`
  margin-right: 15rem;
  display: flex;
  justify-content: flex-end;

  p {
    max-width: 17rem;
    margin-bottom: 0;
    text-align: center;
  }

  @media (max-width: ${S_SCREEN}) {
    margin-right: 10rem;
  }
`;

const ActionArrow = styled.img`
  height: 7rem;
  width: 8rem;
  margin-right: 1rem;

  @media (max-width: ${S_SCREEN}) {
    height: 4rem;
    width: 8rem;
  }
`;

const ActionButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const HeaderContentComponent = () => {
  const { t } = useTranslationContext();
  const themeContext = useTheme();
  const isMediumScreen = useMediaQuery(
    `(min-width: ${MD_SCREEN}) and (max-width: ${LG_SCREEN})`
  );
  const isSmallScreen = useMediaQuery(`(max-width: ${MD_SCREEN})`);

  return (
    <Content>
      <HeaderWrapper>
        <HeaderText>
          <YellowBar className="color-purple">
            {t("index_page.header_title_start").toUpperCase()}
          </YellowBar>
          <br />
          <h3>{t("index_page.header_title_middle").toUpperCase()}</h3>
          <YellowBar className="color-purple">
            {t("index_page.header_title_end").toUpperCase()}
          </YellowBar>
        </HeaderText>
        <ActionWrapper>
          <ActionText>
            <p>{t("index_page.call_to_action_text")}</p>
          </ActionText>
          <ActionButton>
            <ActionArrow src={headerArrow} alt="Arrow to action button" />
            <div>
              <Button color={themeContext.colors.petrol.main}>
                {t("index_page.go_to_lessons").toUpperCase()}
              </Button>
            </div>
          </ActionButton>
        </ActionWrapper>
      </HeaderWrapper>
      {!isSmallScreen && (
        <HeaderImage>
          <img
            src={isMediumScreen ? headerImageSmall : headerImage}
            alt="Smiling girl"
          />
        </HeaderImage>
      )}
    </Content>
  );
};

export default HeaderContentComponent;
