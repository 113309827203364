import React, { useState } from "react";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { Trans } from "react-i18next";

import { useTranslationContext } from "i18n/i18n";
import useScrollIntoView from "hooks/useScrollIntoView";
import CardButton from "components/CardButtonComponent";
import Popup from "components/PopupComponent";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4rem;
  background-color: ${(props) => props.theme.colors.petrol.main};
  color: #fff;

  @media (max-width: 550pc) {
    padding: 1rem;
  }
`;

const Title = styled.h1`
  text-align: center;
`;

const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const SchoolBlockComponent = () => {
  const { t } = useTranslationContext();
  const themeContext = useTheme();
  const wrapperRef = useScrollIntoView("school");
  const [showPopupOne, setShowPopupOne] = useState(false);
  const [showPopupTwo, setShowPopupTwo] = useState(false);

  const buttonProps = {
    bgColor: "#f4f4f5",
    color: themeContext.colors.purple.main,
    shadow: themeContext.colors.purple.main,
  };

  return (
    <>
      <Content ref={wrapperRef}>
        <Title>
          {"{"} {t("school.title")} {"}"}
        </Title>
        <p>{t("school.text")}</p>
        <Cards>
          <CardButton {...buttonProps} onClick={() => setShowPopupOne(true)}>
            <h2>{t("school.card_1")}</h2>
          </CardButton>
          <CardButton {...buttonProps} onClick={() => setShowPopupTwo(true)}>
            <h2>{t("school.card_2")}</h2>
          </CardButton>
        </Cards>
      </Content>

      <Popup show={showPopupOne} onClose={() => setShowPopupOne(false)}>
        <Trans
          transKeepBasicHtmlNodesFor={["a"]}
          i18nKey="school.popup_1"
          // eslint-disable-next-line jsx-a11y/anchor-has-content
          components={{ alink: <a href="mailto:info@hackintheclass.org" /> }}
        />
      </Popup>

      <Popup show={showPopupTwo} onClose={() => setShowPopupTwo(false)}>
        Fill this
      </Popup>
    </>
  );
};

export default SchoolBlockComponent;
