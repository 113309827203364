import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";

import translationEN from "./translations/en/translation.json";
import translationNL from "./translations/nl/translation.json";

export const useTranslationContext = () => useTranslation();

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: translationEN },
    nl: { translation: translationNL },
  },
  lng: "nl",
  fallbackLng: "en",
});

export default i18n;
