import React from "react";
import styled from "@emotion/styled";
import { MdMenu } from "react-icons/md";
import { Link } from "react-router-dom";

import { useTranslationContext } from "i18n/i18n";
import useMediaQuery from "hooks/useMediaQuery";
import { XXS_SCREEN, MD_SCREEN } from "./screenSizes";
import LanguagePicker from "./LanguagePickerComponent";

const Navigation = styled.nav`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 1rem 1rem 0 0;

  a {
    color: ${(props) => props.theme.colors.grey.lighter};
    text-decoration: none;
    margin: 0 0.25rem;

    &:hover {
      font-weight: bold;
    }
  }

  @media (max-width: ${XXS_SCREEN}) {
    margin: 0.5rem 0.5rem 0 0;
  }
`;

const CodeTags = styled.span`
  margin: 0 0.5rem;
  color: ${(props) => props.theme.colors.purple.darker};
  font-family: monospace;
  font-weight: bold;
`;

const MenuButton = styled.button`
  width: 3rem;
  height: 3rem;
  border-radius: 3rem;
  background-color: transparent;
  border: none;
  color: ${(props) => props.theme.colors.grey.lighter};
  display: flex;
  align-items: center;

  &:hover {
    background-color: ${(props) => props.theme.colors.grey.lighter}20;
    cursor: pointer;
  }

  svg {
    height: 3rem;
    width: 3rem;
  }

  @media (max-width: ${XXS_SCREEN}) {
    flex: 1;
  }
`;

const NavigationComponent = ({ onOpenMenu = () => {}, ...props }) => {
  const { t } = useTranslationContext();
  const isSmallScreen = useMediaQuery(`(max-width: ${MD_SCREEN})`);

  return (
    <Navigation {...props}>
      {!isSmallScreen && (
        <>
          <CodeTags>{"<nav>"}</CodeTags>
          <Link to="/">{t("navigation.home")}</Link>
          <Link to="/teaching-materials">
            {t("navigation.teaching_materials")}
          </Link>
          <Link to="/#mission">{t("navigation.mission")}</Link>
          <Link to="/#contact">{t("navigation.contact")}</Link>
          <CodeTags>{"</nav>"}</CodeTags>
          <LanguagePicker />
        </>
      )}
      {isSmallScreen && (
        <MenuButton onClick={onOpenMenu}>
          <MdMenu />
        </MenuButton>
      )}
    </Navigation>
  );
};

export default NavigationComponent;
